import AlphabeticalQuestionnaireList from 'components/_questionnaires/AlphabeticalList';
import { Col, Row } from 'react-bootstrap';
import useQuestionnairesSummaries from 'hooks/useQuestionnairesSummaries';
import styles from './officialQuestionnairesPage.module.scss';
import QuestionnaireTypesPanel from 'components/OfficialQuestionnairesPage/QuestionnaireTypesPanel';
import QuestionnairesListLoader from 'components/_questionnaires/ListLoader';
import useQuestionnaireTypesPanel from './useQuestionnaireTypesPanel';
import QuestionnaireListItemCreate from 'components/_questionnaires/ListItemCreate';
import useModal from 'hooks/useModal';
import CreateOfficialQuestionnaireFormModal from '../_questionnaires/OfficialQuestionnaireFormModal/CreateOfficialQuestionnaireFormModal';
import useCurrentUserPermissions from 'hooks/useCurrentUserPermissions';
import QuestionnaireKind from '_types/api/questionnaires/QuestionnaireKind';

const OfficialQuestionnairesPage = () => {
    const { selectedType, setSelectedType } = useQuestionnaireTypesPanel();
    const [isShowingCreateQuestionnaire, toggleCreateQuestionnaire] = useModal();
    const { officialQuestionnaires } = useCurrentUserPermissions();
    const canCreateOfficialQuestionnaire = officialQuestionnaires?.canCreate;

    const { questionnaires, isLoading } = useQuestionnairesSummaries({
        filters: {
            type: selectedType,
            kind: QuestionnaireKind.OFFICIAL,
        },
    });

    return (
        <Row>
            <Col sm={7}>
                {canCreateOfficialQuestionnaire && (
                    <QuestionnaireListItemCreate onClick={toggleCreateQuestionnaire} text="Add questionnaire" />
                )}
                {isLoading && <QuestionnairesListLoader />}
                {!isLoading && questionnaires && (
                    <AlphabeticalQuestionnaireList colSm={6} questionnaires={questionnaires} />
                )}
            </Col>
            <Col sm={5}>
                <h2 className={styles.title}>
                    Welcome to <br /> the Item Library!
                </h2>
                <p>
                    The <strong>Core questionnaire</strong> QLQ-C30 has been developed to assess the quality of life of
                    cancer patients.
                </p>
                <p>
                    It is supplemented by disease-, symptom- and population-specific questionnaires called{' '}
                    <strong>modules</strong>. You can also find general questionnaires that can be used without the Core
                    questionnaire, called <strong>standalones</strong>.
                </p>
                <p>
                    <strong>Previous versions</strong> are questionnaires that have been updated.
                </p>
                <p>
                    The <strong>CAT</strong> groups all item banks for the Computer Adapted Testing system.
                </p>
                <QuestionnaireTypesPanel onSelectedChange={setSelectedType} selected={selectedType} />
            </Col>
            <CreateOfficialQuestionnaireFormModal
                isShowing={isShowingCreateQuestionnaire}
                toggle={toggleCreateQuestionnaire}
            />
        </Row>
    );
};

export default OfficialQuestionnairesPage;
