import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

type ElementProps = {
    x: number;
    y: number;
    squareSize?: number;
};

const ItemLine = ({ x, y, squareSize = 0 }: ElementProps) => (
    <rect x={x + (squareSize + 15)} y={y} rx="3" ry="3" width="50" height="8" />
);

const Item = ({ x, y, squareSize = 60 }: ElementProps) => (
    <>
        <rect x={x} y={y} rx="3" ry="3" width={squareSize} height={squareSize} />
        <ItemLine x={x} squareSize={squareSize} y={y + 15} />
        <ItemLine x={x} squareSize={squareSize} y={y + 30} />
        <ItemLine x={x} squareSize={squareSize} y={y + 45} />
    </>
);

type ColumnProps = ElementProps & { itemsCount: number };
const Column = ({ y, x, itemsCount }: ColumnProps) => (
    <>
        {Array.from({ length: itemsCount }).map((v, i) => (
            <Item y={y + (i + 1) * 75} x={x} key={y + (i + 1) * 75} />
        ))}
    </>
);

export type QuestionnairesListLoaderProps = IContentLoaderProps & { columnsCount?: number; itemsCount?: number };

const QuestionnairesListLoader = ({ columnsCount = 2, itemsCount = 10, ...props }: QuestionnairesListLoaderProps) => {
    let width = props.width ? props.width : 690;
    const height = props.height ? props.height : 680;
    width = typeof width === 'number' ? width : parseInt(width);
    const colXOffSet = width / columnsCount - 10;
    return (
        <ContentLoader
            speed={2}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor="#f3f3f3"
            foregroundColor="#ededed"
            {...props}
        >
            {Array.from({ length: columnsCount }).map((v, i) => (
                <Column x={i * colXOffSet} y={15} itemsCount={itemsCount} key={i} />
            ))}
        </ContentLoader>
    );
};

export default QuestionnairesListLoader;
